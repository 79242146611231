import { Injectable } from '@angular/core';
import { HttpRequestManager } from 'src/app/http-manager/http-manager.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookupDetailService {

  constructor(private httpManager: HttpRequestManager) { }
//   getNewVendor() {
//     return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/NewVendor')
//   }
  saveDetail(payload) {
    return this.httpManager.post(environment['apibase'] + 'api/LookUpDetail', payload);
  }

  getDetailListing(masterId) {
    return this.httpManager.get(environment['apibase'] + `api/LookUpDetail/listing/${masterId}`);
  }

  deleteDetail(item) {
    return this.httpManager.delete(environment['apibase'] + `api/LookUpDetail/${item.MasterLookupId}?LookupDetailId=${item.LookUpDetailId}`);
  }
//   getVendor(id) {
//     return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/' + id)
//   }

}
