import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestManager } from '../../http-manager/http-manager.service'


@Injectable({
    providedIn: 'root'
})

export class UsersService {

    constructor(private httpManager: HttpRequestManager) { }

    getUsers() {
        return this.httpManager.get(environment['apibase'] + 'api/users/listing')
    }

    getNewUser() {
        return this.httpManager.get(environment['apibase'] + 'api/users/NewUser')
    }

    getUser(id) {
        return this.httpManager.get(environment['apibase'] + 'api/users/' + id)
    }

    saveUser(user) {
        return this.httpManager.post(environment['apibase'] + 'api/users/SaveUser', user)
    }

    deleteUser(id) {
        return this.httpManager.delete(environment['apibase'] + 'api/users/' + id)
    }

    getAssociatedDrones(userId) {
        return this.httpManager.get(environment['apibase'] + 'api/users/associated-drones/' + userId)
    }

    associateDrones(associations) {
        return this.httpManager.post(environment['apibase'] + 'api/users/associate-drones', associations)
    }
}
