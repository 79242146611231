import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs/Rx'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
@Injectable({
  providedIn: 'root',
})
export class SurveysService {
  constructor(private httpClient: HttpClient) {}
  getSurveysById(id: any): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/flight/flights/' + id)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }

  getMissionByProjId(id): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/missions/missionbyprojid/' + id)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  getSurveyOutputs(gufi): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/output/listing/' + gufi)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  getNewMission(pid): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/missions/NewMission/' + pid)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  getMission(gufi): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/missions/' + gufi)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  saveMission(mission): Observable<any> {
    return this.httpClient
      .post(environment['apibase'] + 'api/missions', mission)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  getIndustries(): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/Industry/listing')
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  deleteSurvey(id): Observable<any> {
    return this.httpClient
      .delete(environment['apibase'] + 'api/missions/' + id)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  getFlightList(): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/Flight/listing')
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  getScheduleFlights(): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/FlightSchedule/listing')
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  scheduleFligt(data): Observable<any> {
    return this.httpClient
      .post(environment['apibase'] + 'api/FlightSchedule', data)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  getScheduleFligtById(id: any): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/FlightSchedule/' + id)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  deleteScheduledFlight(id): Observable<any> {
    return this.httpClient
      .delete(environment['apibase'] + 'api/FlightSchedule/' + id)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  getGcpPoints(payload: any): Observable<any> {
    return this.httpClient
      .post(environment['apibase'] + 'flights/GcpPoints/',payload)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  changeScheduleFlightState(payload: any): Observable<any> {
    return this.httpClient
      .put(environment['apibase'] + 'api/FlightSchedule/approvedFlightScheduleStatus',payload)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  
}
