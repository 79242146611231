import { Injectable } from '@angular/core'
import { HttpRequestManager } from 'src/app/http-manager/http-manager.service'
import { environment } from 'src/environments/environment.prod'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AnomaliesManagementService {
  constructor(private httpManager: HttpRequestManager) {}
  getAnamoliesList() {
    return this.httpManager
      .get(environment['apibase'] + 'api/Anomalies/listing')
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
   saveAnomaly(anomaly): Observable<any> {
    return this.httpManager
      .post(environment['apibase'] + 'api/Anomalies', anomaly)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }
  getNewAnomalies() {
    return this.httpManager
      .get(environment['apibase'] + 'api/Anomalies/NewAnomalies')
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  
  getAnomalyById(id) {
    return this.httpManager
      .get(environment['apibase'] + 'api/Anomalies/' + id)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }
  getAnomaliesByIndustryId(industryid) {
    return this.httpManager
      .get(environment['apibase'] + 'api/Anomalies/GetAnomiliesByIndustryId/' + industryid)
      .map((res: Response) => res)
      .catch((error: any) =>
        Observable.throw(error || console.log('Server error'))
      )
  }

 

  // deleteApi(id): Observable<any> {
  //   return this.httpManager
  //     .delete(environment['apibase'] + 'api/Api/' + id)
  //     .map((res: Response) => res)
  //     .catch((error: any) =>
  //       Observable.throw(error || console.log('Server error'))
  //     )
  // }
}
