import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpRequestManager } from '../../http-manager/http-manager.service'
import { ObservableInput, Observable } from 'rxjs'
import { CONSTANT } from 'src/app/components/common/constant/constant'
import { HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class StreamService {
  /* saveOrganization(organizationdata: any) {
    throw new Error("Method not implemented.");
  } */

  constructor(private httpClient: HttpRequestManager) {}

  getStreamingUnitData(): Observable<any> {
    return this.httpClient
      .get(environment['apibase'] + 'api/IoclDumpData')
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error || 'Server error'))
  }

  saveStreamData(value){
    return this.httpClient.post(environment['apibase'] + 'api/IoclDumpData', value)
  }
}
