import { Injectable } from '@angular/core';
import { HttpRequestManager } from 'src/app/http-manager/http-manager.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private httpManager: HttpRequestManager) { }
//   getNewVendor() {
//     return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/NewVendor')
//   }
  saveMaster(payload) {
    return this.httpManager.post(environment['apibase'] + 'api/MasterLookup', payload);
  }

  getMasterListing() {
    return this.httpManager.get(environment['apibase'] + 'api/MasterLookup/listing');
  }

  deleteVendor(id) {
    return this.httpManager.delete(environment['apibase'] + `api/MasterLookup/${id}`);
  }
//   getVendor(id) {
//     return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/' + id)
//   }

}
