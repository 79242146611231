import { Injectable } from '@angular/core';
import { HttpRequestManager } from 'src/app/http-manager/http-manager.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpManager: HttpRequestManager) { }
  getNewVendor() {
    return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/NewVendor')
  }
  saveVendor(vendor) {
    return this.httpManager.post(environment['apibase'] + 'api/VendorManagement', vendor)
  }
  getVendors() {
    return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/listing')
  }
  deleteVendor(id) {
    return this.httpManager.delete(environment['apibase'] + 'api/VendorManagement/' + id)
  }
  getVendor(id) {
    return this.httpManager.get(environment['apibase'] + 'api/VendorManagement/' + id)
  }

}
